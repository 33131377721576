<template>
  <el-form v-loading.fullscreen.lock="loading" @submit.prevent :model="ruleForm" :rules="rules" ref="ruleForm">
    <div class="forget_zone login_zone">
      <BackButton class="mb-3" text="返回" url="/login"></BackButton>
      <div class="register_account">
        <el-form-item prop="account" label="帳號">
          <el-input ref="autoFocus" v-model="ruleForm.account" @keydown.enter.prevent="sendEmail" autocomplete="off" placeholder="請輸入會員Email" />
        </el-form-item>
      </div>
      <div class="text-end mb-4">
        <router-link to="/login/resetPassword">
          <EditButton text="已發送過驗證信？"></EditButton>
        </router-link>
      </div>
      <ConfirmButton class="send_btn" @click="sendEmail" text="發送驗證信"></ConfirmButton>
    </div>
  </el-form>
</template>

<script>
import ConfirmButton from "../../Common/CommonElement/ConfirmButton.vue";
import EditButton from "../../Common/CommonElement/EditButton.vue";
import BackButton from "../../Common/CommonElement/BackButton.vue";

export default {
  name: "RegisterComponent",
  components: {
    ConfirmButton,
    BackButton,
    EditButton,
  },
  data() {
    const validateAccount = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("此為必填資訊"));
      } else if (value.match(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/)) {
        callback();
      } else {
        callback(new Error("請填入正確格式"));
      }
    };
    return {
      loading: false,
      ruleForm: {
        account: "",
      },
      rules: {
        account: [{ validator: validateAccount, trigger: "change" }],
      },
    };
  },
  mounted() {
    this.$nextTick(() => this.$refs.autoFocus.focus());
  },
  methods: {
    async sendEmail() {
      const vm = this;
      vm.$refs["ruleForm"].validate(valid => {
        if (valid) {
          vm.loading = true;
          const user = {
            account: vm.ruleForm.account,
          };
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/password/temp`, user)
            .then(() => {
              vm.notify("success", "發送驗證成功！");
              vm.$store.state.resetPassword.account = vm.ruleForm.account;
              vm.$router.push("/login/resetPassword");
            })
            .catch(err => {
              console.log(err.response.data);
              vm.notify("error", `發送驗證失敗，${err.response.data.Msg}!`);
            })
            .finally(() => (vm.loading = false));
        }
        else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forget_zone {
  height: 100%;
  text-align: start;
  .send_btn {
    color: white;
    width: 100%;
  }
  .reset_page {
    color: $edit_button_color;
    font-size: 0.85rem;
    transition: $main_transition;
    &:hover {
      color: $edit_button_hover_color;
    }
  }
  .el-form-item {
    display: block;
    text-align: start;
    margin: 0;
  }
}
:deep .el-form-item {
  label {
    font-weight: $main_fontWeight;
    color: $text_color;
    font-size: 1rem;
  }
}
</style>
